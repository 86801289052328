<template>
  <section>
    <RouterLink
      v-if="hasIndividualMessage"
      to="/individual"
      tag="button"
      class="btn btn-success my-2"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14px"
        height="14px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-star"
      ><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" /></svg>
      Materiały indywidualne
    </RouterLink>
    <RouterLink
      v-if="showChemmystery"
      to="/chemmystery"
      tag="button"
      class="btn btn-success my-2"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14px"
        height="14px"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-star"
      ><polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" /></svg>
      ChemMystery
    </RouterLink>


    <div class="grid-view">
      <b-card
        v-for="item in courses"
        :key="item.id"
        class="ecommerce-card"
        no-body
      >
        <div class="item-img text-center">
          <b-link :to="`/moje-kursy/${item.slug}`">
            <b-img
              :alt="`${item.title}-${item.id}`"
              fluid
              class="card-img-top"
              :src="`${item.thumbnail}`"
            />
          </b-link>
        </div>

        <!-- Product Details -->
        <b-card-body>
          <div class="item-wrapper">
            <div>
              <h3>{{ item.title }}</h3>
            </div>
          </div>
          <b-card-text class="text-center">
            <h6
              v-if="!item.is_expiring"
              class="text-info"
            >
              Dostęp wygasa: {{ item.expiry_date }}
            </h6>
            <h6
              v-else
              class="text-danger"
            >
              Dostęp wygasa: {{ item.expiry_date }}
            </h6>
          </b-card-text>
        </b-card-body>

        <!-- extend subscription modal -->
        <b-modal
          :id="'subscription-types-' + item.id"
          title="Przedłuż subskrypcję"
          hide-footer
        >
          <select
            v-model="chosenSubscriptionExtension"
            class="form-control my-2"
          >
            <option
              v-for="subscription in item.subscription_types"
              :value="subscription.value"
            >
              {{ subscription.name }} - {{ subscription.price }} zł
            </option>
          </select>
          <b-button
            variant="primary"
            class="mb-2"
            @click="handleCartActionClick(item, chosenSubscriptionExtension)"
          >
            Przedłuż subskrypcję
          </b-button>
        </b-modal>
        <b-modal
          :id="'extend-course-types-' + item.id"
          title="Przedłuż dostęp do Kursu"
          hide-footer
        >
          <h3>{{ item.title }}</h3>
          <select
            v-model="chosenExtensionOption"
            class="form-control my-2"
          >
            <option
              selected
              disabled
              value=""
            >
              Wybierz wariant przedłużenia
            </option>
            <option
              v-for="option in item.extend_options"
              :value="option.value"
            >
              {{ option.name }} - {{ option.price }} zł
            </option>
          </select>
          <b-button
            variant="primary"
            class="mb-2"
            @click="handleCartActionClick(item, chosenExtensionOption)"
          >
            Przedłuż dostęp
          </b-button>
        </b-modal>
        <!-- Product Actions -->
        <div class="item-options text-center">
          <b-button
            variant="primary"
            tag="a"
            class="btn-cart"
            :to="`/moje-kursy/${item.slug}`"
          >
            <span>Przejdź do kursu</span>
          </b-button>
          <b-button
            v-if="item.is_expiring && item.can_be_extended"
            variant="danger"
            tag="a"
            class="btn-cart"
            @click="extend(item)"
          >
            <span>Przedłuż dostęp do kursu</span>
          </b-button>
        </div>
      </b-card>
    </div>
  </section>
</template>
<script>
import {
  BButton, BCard, BCardBody, BCardText, BImg, BLink, BListGroup, BListGroupItem, BTable,
} from 'bootstrap-vue'
import { useEcommerceUi } from '@/views/shop/useEcommerce'

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BLink,
    BImg,
    BListGroup,
    BListGroupItem,
    BButton,
  },
  data() {
    return {
      chosenSubscriptionExtension: {
        type: Number,
      },
      showChemmystery: false,
      hasIndividualMessage: false,
      chosenExtensionOption: '',
      fields: [
        {
          key: 'title',
          label: 'Nazwa kursu',
        },
        {
          key: 'slug',
          label: 'Akcje',
        },
      ],
      courses: [],
    }
  },
  created() {
    this.fetchCourses()
    this.chemmystery()
  },
  setup() {
    const { handleCartActionClick } = useEcommerceUi()

    return { handleCartActionClick }
  },
  methods: {
    fetchCourses() {
      this.$http.get(`${this.$store.state.apiDomain}/api/my/courses`).then(response => {
        this.courses = response.data.courses
        this.hasIndividualMessage = response.data.has_individual_message
      })
    },

    chemmystery() {
      this.$http.get(`${this.$store.state.apiDomain}/api/chemmystery/getCurrentVariants`).then(response => {
        if (response.data.hasChemMystery) {
          this.courses = response.data
          this.showChemmystery = response.data.hasChemMystery
        }
      })
    },
    extend(course) {
      if (course.subscription) {
        this.$bvModal.show(`subscription-types-${course.id}`)
      } else {
        this.$bvModal.show(`extend-course-types-${course.id}`)
      }
    },
  },
}
</script>

<style lang="scss">
.grid-view {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 2rem;
  &.wishlist-items {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .ecommerce-card {
    overflow: hidden;

    // Item Image
    .item-img {
      padding-top: 0.5rem;
      min-height: 15.85rem;
      display: flex;
      align-items: center;
    }

    // Item content wrapper
    .item-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }

    // Hiding list view items
    .shipping,
    .item-company,
    .item-options .item-price {
      display: none;
    }

    .item-options {
      display: flex;
      flex-wrap: wrap;

      .btn-cart,
      .btn-wishlist {
        flex-grow: 1;
        border-radius: 0;
      }
    }

    .item-name {
      margin-top: 0.75rem;
    }

    // Item Description
    .item-description {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-top: 0.2rem;
    }

    .card-body {
      padding: 1rem;
    }
  }
}
@media all and (max-width: 768px){
  .grid-view {
    grid-template-columns: 1fr;
  }
}
</style>
